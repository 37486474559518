import React, {useEffect, useRef, useState} from 'react';
import {Box, Heading, Flex, Text} from "rebass/styled-components";
import styled, {useTheme, css} from "styled-components";
import PropTypes from "prop-types";
import { useTranslation, i18n } from 'next-i18next';
import useCarMapping from "../../hooks/useCarMapping";
import loadable from '@loadable/component';
import Image from 'next/image';

const TradeCarsSection = loadable(() => import('./components/TradeCarsSection'));
const OurBenefits = loadable(() => import('./components/OurBenefits'));
const PurchaseStages = loadable(() => import('./components/PurchaseStages'));


export const sectionHeadingStyles = {
  color: 'text.primary',
  my: [null, null, null, 2],
  textAlign: 'center'
}

const Home = (props) => {
  const {screenWidth: width, stockCars, orderCars, shippingCars, banner} = props;

  // state
  const [discountEnabled, setDiscountEnabled] = useState(false);

  const [mappedStockCars, setMappedStockCars] = useState([]);
  const [mappedOrderCars, setMappedOrderCars] = useState([]);
  const [mappedShippingCars, setMappedShippingCars] = useState([]);

  const videoContainerRef = useRef();
  const theme = useTheme();
  const mapCar = useCarMapping();
  const { t } = useTranslation('common');
  const { language: currentLanguage } = i18n || {};

  const laptopWidth = theme.breakpointsNumeric[2];
  const mobileWidth = theme.breakpointsNumeric[1];
  const smMobileWidth = theme.breakpointsNumeric[0];

  useEffect(() => {
    setMappedStockCars([
      ...stockCars.map(mapCar)
    ])
    setMappedOrderCars([
      ...orderCars.map(mapCar)
    ])
    setMappedShippingCars([
      ...shippingCars.map(mapCar)
    ])
  }, [orderCars, shippingCars, stockCars]);

  useEffect(() => {
    setDiscountEnabled(banner?.enabled);
  }, [banner]);

  return (
    <>
      <Section
        isSmMobile={width <= smMobileWidth}
        id='section-1'
        fullWidth
        heading
        sx={{
          position: 'relative',
          // maxHeight: '820px',
          minHeight: '325px'
        }}
      >

        <PageContent title>
          <MainTitle
            as='h1'
            fontFamily='secondary'
          >
            {t('mainPageHeader')}
          </MainTitle>
          <SubTitle
            fontFamily='primary'
            color='text.primary'
          >
            {t('mainPageSubHeader')}
          </SubTitle>
        </PageContent>

        {width <= mobileWidth && (
          <>
            <ImageOverlay/>
            <ImageContainer
              sx={{
                minHeight: width <= mobileWidth ? 500 : 800
              }}
            >
              <CarHeaderImage
                priority
                strategy='lazy'
                alt='carfind homepage image'
                layout='fill'
                height={width <= mobileWidth ? 500 : 800}
                src='/static-web/images/bg-main.jpg'
              />
            </ImageContainer>
          </>
        )}

        {width > mobileWidth && (
          <>
            <VideoOverlay/>
            <VideoContainer ref={videoContainerRef}>
              <video poster="/static-web/videos/IMG_8654_compressed.mp4" id="bgvid" autoPlay loop muted width={1600} height={900}>
                <source src="/static-web/videos/IMG_8654_compressed.mp4" type="video/webm"/>
                <source src="/static-web/videos/IMG_8654_compressed.mp4" type="video/ogv"/>
                <source src="/static-web/videos/IMG_8654_compressed.mp4" type="video/mp4"/>
              </video>
            </VideoContainer>
          </>
        )}
      </Section>

      {!!discountEnabled && (
        <Section
          isSmMobile={width <= smMobileWidth}
          id='section-discount'
          fullWidth
          heading
        >
          <BannerWrapper>
            <Image
              strategy='lazy'
              width={1200}
              height={250}
              alt='promotion-banner'
              src={`/static-web/images/banners/sale_${banner.current_banner_id}_${currentLanguage}-min.png`}
            />
          </BannerWrapper>
        </Section>
      )}

      <Section
        isSmMobile={width <= smMobileWidth}
        id='section-2'
        fullWidth
        customBgColor='white'
        sx={{
          mt: '0 !important',
          mb: 50,
        }}
      >
        {width > mobileWidth && (
          <Heading
            sx={{
              ...sectionHeadingStyles
            }}
          >
            <Text
              variant='heading.xl'
              color='text.secondary'
              fontFamily='secondary'
            >
              {/*{t('mainPageTradeCarsSectionTitle')}*/}
            </Text>
          </Heading>
        )}
        <PageContent
          sx={{
            mb: 35
          }}
        >
          <TradeCarsSection
            screenWidth={width}
            stockCars={mappedStockCars}
            orderCars={mappedOrderCars}
            shippingCars={mappedShippingCars}
          />
        </PageContent>
      </Section>

      <Section
        isSmMobile={width <= smMobileWidth}
        id='section-3'
        fullWidth
        customBgColor='rgba(255,255,255,0.2)'
        sx={{
          overflow: 'hidden',
          mb: '0 !important',
        }}
      >
        <PageContent>
          <PurchaseStages screenWidth={width}/>
        </PageContent>
      </Section>

      <Section
        isSmMobile={width <= smMobileWidth}
        id='section-4'
        fullWidth
        customBgColor='white'
        sx={{
          px: '0 !important',
          m: '0 auto 15px !important'
        }}
      >
        <PageContent>
          <OurBenefits screenWidth={width}/>
        </PageContent>
      </Section>
    </>
  )
}

const ImageContainer = styled(Box)`
  width: 100%;
  height: fit-content;
  background: #F6F7F9;
`

const ImageOverlay = styled(Box)`
  position: absolute;
  width: 100%;
  height: calc(100% - 3px);
  background: rgba(0, 0, 0, 0.4);
  min-height: 325px;
  z-index: 1;
`

const CarHeaderImage = styled(Image)`
  background: #F6F7F9;
  width: 100%;
  max-height: fit-content;
  object-fit: cover;
  min-height: 325px;
  max-height: 820px;
  position: relative;
  left: 0;
  top: 0;

  //@media (max-width: 1600px) {
  //  height: 750px;
  //}
  //
  //@media (max-width: 1000px) {
  //  min-height: 200px;
  //}
  //
  //@media (max-width: 700px) {
  //  min-height: 185px;
  //}
  //
  //@media (max-width: 600px) {
  //  height: 550px;
  //}
`

const BannerWrapper = styled(Box)`
  width: 100%;
  position: relative;
  
  span {
    width: 100% !important;
  }
  
  img {
    width: 100% !important;
    height: auto;
  }

  @media (max-width: 900px) {
    min-height: 175px;
  }

  @media (max-width: 600px) {
    min-height: 125px;
  }
`

export const Section = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: fit-content;
  //@media (min-width: 900px) {
  //  max-height: 100vh;
  //}

  padding: ${({heading}) => heading ? 0 : '20px 50px 40px'};
  max-width: ${({fullWidth, theme}) => fullWidth ? '100%' : theme.breakpoints[2]};
  background-color: ${({customBgColor, theme}) => {
    return customBgColor ? theme.colors.background[customBgColor] : 'inherit'
  }};
  height: ${(({staticHeight}) => staticHeight ? `${staticHeight}px` : 'fit-content')};

  :not(:first-child) {
    margin: 50px auto;
  }
  
  &#section-discount {
    margin: -3px 0 0 0;
  }

  ${({isSmMobile}) => isSmMobile && css`
    padding: ${({heading}) => heading ? 0 : '20px 20px 30px'};
  `}
`

export const MainTitle = styled(Heading)`
  font-weight: bold;
  position: absolute;
  color: #fff;
  right: calc(100% - 815px);
  width: 750px;
  text-transform: uppercase;
  padding: 0;
  font-size: 58px;
  line-height: 60px;
  top: 225px;

  @media (max-width: 1600px) {
    left: 13.5%;
  }

  @media (max-width: 1200px) {
    left: 55px;
    top: 150px;
    padding: 12px;
  }

  @media (max-width: 900px) {
    top: 80px;
    width: 75%;
    padding: 0;
    font-size: 36px;
    line-height: 36px;
  }
  
  @media (max-width: 600px) {
    top: 80px;
    left: 50%;
    font-size: 28px;
    max-width: 100%;
    text-align: left;
    padding: 0 36px;
    
    width: 100%;
    transform: translateX(-50%);
  }
`;

export const SubTitle = styled(Heading)`
  max-width: 360px;
  line-height: 22px;
  text-transform: uppercase;
  margin-top: 25px;
  position: absolute;
  font-weight: 200;
  padding: 0;
  right: calc(100% - 425px);
  font-size: 18px;
  top: 500px;
  
  @media (max-width: 1600px) {
    left: 13.5%;
    width: 360px;
  }

  @media (max-width: 1200px) {
    padding: 12px;
    left: 55px;
    top: 415px;
  }

  @media (max-width: 900px) {
    padding: 0;
    font-size: 16px;
    top: 280px;
  }

  @media (max-width: 600px) {
    //border-top: 1px solid #fff;
    max-width: 100%;
    margin: 0 auto;
    text-align: left;
    font-size: 12px;
    top: 375px;

    left: 50%;
    width: 100%;
    padding: 10px 36px 0;
    transform: translateX(-50%);
  }
`;

export const PageContent = styled(Flex)`
  padding: 0;
  flex-direction: column;
  width: 100%;
  max-width: 1600px;
  position: relative;
  z-index: 99;

  @media (max-width: 1600px) {
    padding: ${({ theme }) => `0 ${theme.pageGutters[4]}`};
  }

  @media (max-width: 1200px) {
    padding: 0;
  }
`

export const VideoOverlay = styled(Box)`
  position: absolute;
  width: 100%;
  height: calc(100% - 3px);
  background: rgba(0, 0, 0, 0.6);
  min-height: 325px;
  max-height: 100vh;
`

export const VideoContainer = styled(Box)`
  width: 100%;
  height: fit-content;
  min-height: 325px;
  max-height: 100vh;

  video {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    min-height: 325px;
    object-fit: fill;
  }
`

Home.propTypes = {
  screenWidth: PropTypes.number.isRequired,
  stockCars: PropTypes.array,
  orderCars: PropTypes.array,
  shippingCars: PropTypes.array,
}

Home.defaultProps = {
  stockCars: [],
  orderCars: [],
  shippingCars: [],
}

export default Home;
