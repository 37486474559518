export const carBodyTypes = [{
    ua: 'Седан',
    ru: 'Седан',
    value: 'sedan'
  },
  {
    ua: 'Купе',
    ru: 'Купе',
    value: 'coupe'
  },
  {
    ua: 'Спорт кар',
    ru: 'Спорт кар',
    value: 'sports-car'
  },
  {
    ua: 'Універсал',
    ru: 'Универсал',
    value: 'station-wagon'
  },
  {
    ua: 'Хетчбек',
    ru: 'Хэтчбек',
    value: 'hatchback'
  },
  {
    ua: 'Позашляховик',
    ru: 'Внедорожник',
    value: 'suv'
  },
  {
    ua: 'Кроссовер',
    ru: 'Кроссовер',
    value: 'crossover'
  },
  {
    ua: 'Пікап',
    ru: 'Пикап',
    value: 'pickup'
  },
  {
    ua: 'Міні вен',
    ru: 'Мини вен',
    value: 'van'
  },
  {
    ua: 'Інше',
    ru: 'Другое',
    value: 'other'
  }
];

export const carStatuses = [
  {
    ua: 'В наявності',
    ru: 'В наличии',
    value: 'stock'
  },
  {
    ua: 'Під замовлення',
    ru: 'Под заказ',
    value: 'order'
  },
  {
    ua: 'В дорозі',
    ru: 'В пути',
    value: 'shipping'
  },
  {
    ua: 'Tesla',
    ru: 'Tesla',
    value: 'tesla'
  },
  {
    ua: 'З Китаю',
    ru: 'Из Китая',
    value: 'china'
  }
];

export const currencies = [
  {
    ua: '$',
    ru: '$',
    value: 'USD'
  },
  {
    ua: '€',
    ru: '€',
    value: 'EUR'
  }
];

export const wheelDriveTypes = [
  {
    ua: 'Передній',
    ru: 'Передний',
    value: 'front'
  },
  {
    ua: 'Задній',
    ru: 'Задний',
    value: 'rear'
  },
  {
    ua: 'Повний',
    ru: 'Полный',
    value: 'all'
  }
];

export const transmissions = [
  {
    ua: 'Ручна',
    ru: 'Ручная',
    value: 'manual'
  },
  {
    ua: 'Автомат',
    ru: 'Автомат',
    value: 'automatic'
  },
  {
    ua: 'Робот',
    ru: 'Робот',
    value: 'robot'
  },
  {
    ua: 'Типтронік',
    ru: 'Типтроник',
    value: 'tiptronic'
  },
  {
    ua: 'Варіатор',
    ru: 'Вариатор',
    value: 'variator'
  }
];

export const mileageUnits = [
  {
    ua: 'км',
    ru: 'км',
    value: 'km'
  },
  {
    ua: 'миль',
    ru: 'миль',
    value: 'm'
  }
];

export const fuels = [
  {
    ua: 'Бензин',
    ru: 'Бензин',
    value: 'gas'
  },
  {
    ua: 'Дизель',
    ru: 'Дизель',
    value: 'diesel'
  },
  {
    ua: 'Електро',
    ru: 'Электро',
    value: 'electric'
  },
  {
    ua: 'Гібрид',
    ru: 'Гибрид',
    value: 'hybrid'
  }
];

export const carSortingOptions = [
  {
    ua: 'За замовчуванням',
    ru: 'По умолчанию',
    value: {
      fieldName: 'created',
      type: 'desc'
    }
  },
  {
    ua: 'По ціні (дешеві)',
    ru: 'По цене (дешевые)',
    value: {
      fieldName: 'price',
      type: 'asc'
    }
  },
  {
    ua: 'По ціні (дорогі)',
    ru: 'По цене (дорогие)',
    value: {
      fieldName: 'price',
      type: 'desc'
    }
  },
  {
    ua: 'По року випуску (старі)',
    ru: 'По году выпуска (старые)',
    value: {
      fieldName: 'year',
      type: 'asc'
    }
  },
  {
    ua: 'По року випуску (нові)',
    ru: 'По году выпуска (новые)',
    value: {
      fieldName: 'year',
      type: 'desc'
    }
  }
];
