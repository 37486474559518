import React, {useCallback} from 'react';
import {
  carBodyTypes,
  carStatuses,
  currencies,
  fuels,
  mileageUnits,
  transmissions,
  wheelDriveTypes
} from "../constants/carAttributesMap";
import {i18n} from "next-i18next";

function useCarMapping() {

  const { language: resolvedLanguage } = i18n;

  return useCallback((carResponse) => {
    let mileage = carResponse.mileage;
    if (carResponse.mileage_units === 'm') { // convert miles to km
      mileage = (carResponse.mileage * 1.609).toFixed(0);
    }
    return {
      ...carResponse,
      mileage,
      images: JSON.parse(carResponse.images),
      engineCapacity: (carResponse.engine_capacity) / 1000,
      comfort: JSON.parse(carResponse.comfort),
      multimedia: JSON.parse(carResponse.multimedia),
      safety: JSON.parse(carResponse.safety),
      isLive: carResponse.is_live,
      fuel: fuels.find(f => f.value === carResponse.fuel)?.[resolvedLanguage] || carResponse.fuel,
      currency: currencies.find(c => c.value === carResponse.currency)?.[resolvedLanguage] || carResponse.currency,
      mileageUnits: mileageUnits.find(m => m.value === carResponse.mileage_units)?.[resolvedLanguage] || carResponse.mileage_units,
      carBodyType: carBodyTypes.find(cb => cb.value === carResponse.car_body_type)?.[resolvedLanguage] || carResponse.car_body_type,
      status: carStatuses.find(cb => cb.value === carResponse.status)?.[resolvedLanguage] || carResponse.status,
      transmission: transmissions.find(cb => cb.value === carResponse.transmission)?.[resolvedLanguage] || carResponse.transmission,
      wheelDriveType: wheelDriveTypes.find(cb => cb.value === carResponse.wheel_drive_type)?.[resolvedLanguage] || carResponse.wheel_drive_type,
    }
  }, [resolvedLanguage])
}

export default useCarMapping;
